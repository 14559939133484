import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { getToken } from '../functions/TokenFunctions';

const Users: React.FC = () => {
    const {getAccessTokenSilently} = useAuth0();
    const [userDataPrivate, setUserDataPrivate] = useState(null);
    const [userDataPrivateScoped, setUserDataPrivateScoped] = useState(null);
    let savedAccessToken = getToken("accessToken");

    useEffect(() => {
        console.log("AccessToken: " + savedAccessToken);

        const fetchUsersPrivate = async () => {
            if (!savedAccessToken){
                try {
                    const accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: "https://lorevault-api"
                        }
                    });
                    console.log("access token: " + accessToken);
    
    
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_API_ROUTE!}/User/get-users-private`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    });
    
                    setUserDataPrivate(response.data);
                }
                catch (e) {
                    console.log(e);
                }
            } else {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_API_ROUTE!}/User/get-users-private`,
                    {
                        headers: {
                            Authorization: `Bearer ${savedAccessToken}`
                        }
                    });
    
                    setUserDataPrivate(response.data);
                }
                catch (e) {
                    console.log(e);
                }
            }
            
        };

        const fetchUsersPrivateScoped = async () => {
            if (!savedAccessToken){
                try {
                    const accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: "https://lorevault-api",
                            //scope: "read_users"
                        }
                    });

                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_API_ROUTE!}/User/get-users-private-scoped`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    });

                    setUserDataPrivateScoped(response.data);
                }
                catch (e) {
                    console.log(e);
                }
            }
            else {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_API_ROUTE!}/User/get-users-private-scoped`,
                    {
                        headers: {
                            Authorization: `Bearer ${savedAccessToken}`
                        }
                    });

                    setUserDataPrivateScoped(response.data);
                }
                catch (e) {
                    console.log(e);
                }
            }
        };

        fetchUsersPrivate();
        fetchUsersPrivateScoped();
    // }, [getAccessTokenSilently]);
    }, []);

    return(
        <div className="App">
            <header className="App-header">
                <h1>Users Endpoints Test</h1>
                {savedAccessToken ? (
                    <div>
                        {userDataPrivate ? (
                            <pre>Users private: {JSON.stringify(userDataPrivate, null, 2)}</pre>
                        ) : ("Loading private endpoint data...")}
                        {userDataPrivateScoped ? (
                            <pre>Users private scoped: {JSON.stringify(userDataPrivateScoped, null, 2)}</pre>
                            ) : ("Loading private scoped endpoint data...")}
                            <div>
                                <Link to="/">Home</Link>
                            </div>
                    </div>) : (
                        <h2>Not logged in</h2>
                        )}
            </header>
        </div>
    );
};

export default Users