import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

const Profile: React.FC = () => {
    const {user, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);

    useEffect(() => {
      const getUserMetadata = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
              scope: process.env.REACT_APP_AUTH0_SCOPE!,
              prompt: "consent",
            },
          });

          const userDetailsByIdUrl = `${process.env.REACT_APP_AUTH0_AUDIENCE!}users/${user?.sub}`;

          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          });

          const { user_metadata } = await metadataResponse.json();
          setUserMetadata(user_metadata);
        } catch (e) {
          console.log(e);
        }
      };

      getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]);

    if (isLoading) return <div>Loading...</div>;

    return(
        <div className="App">
      <header className="App-header">
      <h1>Profile Page</h1>
        {isAuthenticated && user ? (
          <div>
            <img src={user.picture} alt={user.name}/>
            <h2>User Name: {user.name}</h2>
            <p>Email: {user.email}</p>
            <div>
              {userMetadata ? (
                <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
              ) : (
                "No user data defined"
              )}
            </div>
          </div>
        ) : (
          <h2>Not logged in</h2>
        )}
        <Link to="/">Home</Link>
      </header>
    </div>
    );
};

export default Profile