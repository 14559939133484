import axios, { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getToken, isTokenValid, removeToken, saveToken, getCookie, setCookie, deleteCookie } from '../functions/TokenFunctions';

const Home: React.FC = () => {
const {getAccessTokenSilently, getIdTokenClaims, loginWithRedirect, logout, isAuthenticated, isLoading, error, user} = useAuth0();
const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

const handleLogin = async () => {
  await loginWithRedirect({
    authorizationParams : {scope: "openid profile email", audience: "https://lorevault-api", connection: "google-oauth2", 
      prompt: "select_account", redirect_uri : "http://localhost:3000"}
  });
};

useEffect(() => {
  const checkAuthentication = async () => {
    const savedAuthToken = getToken("authToken");

    if (savedAuthToken && isTokenValid(savedAuthToken)) {
      console.log("Token already exists and is valid. User is authenticated.");
      console.log("Stored authToken: " + savedAuthToken);

      // Fetch access token if user is authenticated
      const savedAccessToken = getToken("accessToken");
      if (savedAccessToken && isTokenValid(savedAccessToken)) {
        console.log("Stored accessToken: " + savedAccessToken);
        setIsUserLoggedIn(true);
        return;
      }
      else {
        console.log("Access token not stored. Attempting to fetch.");
        getAndSaveAccessToken();
        setIsUserLoggedIn(true);
      }
    } else {
      console.log("No valid token found. Attempting to fetch ID token.");
    }
  };

  checkAuthentication();
}, []);

useEffect(() => {
  const fetchAndStoreToken = async () => {
    if (isAccessTokenSaved() && isAuthTokenSaved()) {
      setIsUserLoggedIn(true);
      return;
    }

    if (isAuthenticated) {
      const token = await getIdTokenClaims();
      const idToken = token?.__raw;
      console.log("id_token: " + idToken);

      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API_ROUTE!}/User/login-with-google`,
        { idToken }, 
        { headers: {"Content-Type": "application/json"} 
      });

      if (result.status === 200) {
        const data: AxiosResponse = result.data;
        console.log("Backend token: " + data);

        saveToken("authToken", String(data));

        // Fetch access token and save it for API protected endpoints
        getAndSaveAccessToken();
        setIsUserLoggedIn(true);
      } else {
        console.error("Failed to log in", result.data);
      }
    }
  };

  fetchAndStoreToken();
}, [isAuthenticated, getIdTokenClaims]);

const isAuthTokenSaved = (): boolean => {
  const savedAuthToken = getToken("authToken");
  if (savedAuthToken) {
    return true;
  }
  else {
    return false;
  }
};

const isAccessTokenSaved = (): boolean => {
  const savedAccessToken = getToken("accessToken");
  if (savedAccessToken) {
    return true;
  }
  else {
    return false;
  }
};

const getAndSaveAccessToken = async () => {
  try {
    const accessToken = await getAccessTokenSilently({
        authorizationParams: {
            audience: "https://lorevault-api"
        }
    });
    console.log("access token: " + accessToken);
    saveToken("accessToken", accessToken);
  }
  catch (e) {
      console.log(e);
  }
};

if (isLoading) {
  return <div>Loading authentication...</div>;
}

if (error) {
console.log("Authentication error: ", error.message)
}

return (
    <div className="App">
      <header className="App-header">
      <h1>LoreVault</h1>
        {isUserLoggedIn ? (
          <div>
            <p>You are logged in!</p>
            <Link to="/profile">Check your profile</Link><br/>
            <Link to="/users">User endpoints</Link>
          </div>
        ) : (
          <p>You are not logged in!</p>
        )}
        <>
            <button onClick={() => handleLogin()}>
                Log in with Google
            </button>
            <button onClick={() => logout({ logoutParams: {returnTo: window.location.origin}})}>
              Log out
            </button>
        </>
      </header>
    </div>
  );
};

export default Home;