import './App.css';
import Home from './pages/Home'
import Profile from './pages/Profile';
import Users from './pages/Users';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

function App() {
  return (
    <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN!} clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!} authorizationParams={{ 
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      scope: process.env.REACT_APP_AUTH0_SCOPE!
      }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path='/profile' element={<Profile/>} />
          <Route path='/users' element={<Users/>} />
        </Routes>
      </BrowserRouter>
    </Auth0Provider>
  );
}

export default App;
