import { jwtDecode, JwtPayload } from "jwt-decode";
import { Buffer } from "buffer";

type DecodedToken = JwtPayload & { [key: string]: any }; // Extend JwtPayload for custom claims

/**
 * Checks if the token is valid.
 * @param token - The JWT token to validate.
 * @returns `true` if the token is valid, otherwise `false`.
 */
export function isTokenValid(token: string): boolean {
    try {
        // Decode the token
        const decoded = jwtDecode<DecodedToken>(token);

        // Check if the token has expired
        if (!decoded.exp) return false;

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        return decoded.exp > currentTime;
    } catch (err) {
        console.error("Error decoding token:", err);
        return false;
    }
}

export function saveToken(tokenName: string, token: string): void {
    setCookie(tokenName, token);
    sessionStorage.setItem(tokenName, token);
}

export function getToken(tokenName: string): string | null {
    return sessionStorage.getItem(tokenName);
}

export function removeToken(tokenName: string): void {
    sessionStorage.removeItem(tokenName);
}

//TODO: change to use Buffer.from instead of atob()
export function setCookie(name: string, token: string): void {
    try {
        // const payload = JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
        const payload = JSON.parse(atob(token.split(".")[1]));
        const expires = new Date(payload.exp * 1000);

        document.cookie = `${name}=${token};expires=${expires.toUTCString()};path=/;secure;HttpOnly`;
    } catch (error) {
        console.error("Failed to set cookie with token expiration: ", error);
    }
}

export function getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
}

export function deleteCookie(name: string): void {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}